@font-face {
	font-family: 'sftm';
	src: url('SFProText-Regular.otf');
	font-style: normal;
}

@font-face {
	font-family: 'sfdb';
	src: url('SFProDisplay-Bold.ttf');
	font-style: normal;
}
