.bg-tasks-top {
    top: 0;
    background-image: linear-gradient(90deg, #6DAC45, #7DB247) !important;
}

.bg-tasks-bottom {
    bottom: 0;
    background-image: linear-gradient(90deg, #3d993d, #4EA041) !important;
}

.bg-dating-top {
    top: 0;
    background-image: linear-gradient(90deg, #27BFCC, #28A8CC) !important;
}

.bg-dating-bottom {
    bottom: 0;
    background-image: linear-gradient(90deg, #2977CB, #2960CB) !important;
}

.bg {
    height: 50vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
